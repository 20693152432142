// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";
import "bootstrap";
import "popper.js";
import Bugsnag from "@bugsnag/js";

Bugsnag.start({ apiKey: "f5a2e8a7f62e65cc07474ee748f48648" });

// https://stackoverflow.com/questions/58743392/jquery-and-not-defined-in-like-js-erb-returned-from-ajax-call
window.$ = $;

$(document).on("turbolinks:load", function () {
  $(".js-toggle-all").on("change", () => {
    const isChecked = $(".js-toggle-all").prop('checked')
    $(".js-seat-checkbox").prop("checked", isChecked)
  })
  $('[data-toggle="tooltip"]').tooltip();
  $(".js-select-person").change(function () {
    if ($(this)[0].value === "GUEST") {
      $(".js-guest-name input").attr("required", "required");
      $(".js-guest-name").slideDown();
    } else {
      $(".js-guest-name input").removeAttr("required");
      $(".js-guest-name").slideUp();
    }
  });

  $("#exampleModal").on("show.bs.modal", function (event) {
    var button = $(event.relatedTarget); // Button that triggered the modal
    var roomName = button.data("roomname"); // Extract info from data-* attributes
    var dateLabel = button.data("datelabel");
    var date = button.data("date");
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this);
    modal
      .find("#select-label")
      .text(
        "Für welchen Mitarbeiter soll Raum " + roomName + " gebucht werden? "
      );
    modal.find("#booking-date").val(date);
    modal.find("#booking-room").val(roomName);
    modal.find(".modal-title").text("Buchung am " + dateLabel);
  });

  $("#reservationModal").on("show.bs.modal", function (event) {
    var button = $(event.relatedTarget); // Button that triggered the modal
    var roomName = button.data("roomname"); // Extract info from data-* attributes
    var dateLabel = button.data("datelabel");
    var date = button.data("date");
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this);
    modal
      .find("#sure")
      .text(
        "Sind Sie sich sicher, dass Sie den Raum " +
          roomName +
          " für " +
          dateLabel +
          " buchen wollen?"
      );
    modal.find("#booking-date").val(date);
    modal.find("#booking-room").val(roomName);
    modal.find(".modal-title").text("Buchung am " + dateLabel);
  });

  $("#reservationRequestModal").on("show.bs.modal", function (event) {
    var button = $(event.relatedTarget); // Button that triggered the modal
    var roomName = button.data("roomname"); // Extract info from data-* attributes
    var dateLabel = button.data("datelabel");
    var date = button.data("date");
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this);
    modal
      .find("#sure")
      .text(
        "Sie können Raum " +
          roomName +
          " nicht direkt buchen. Möchten Sie für " +
          dateLabel +
          " eine Buchungsanfrage stellen?"
      );
    modal.find("#booking-date").val(date);
    modal.find("#booking-room").val(roomName);
    modal.find(".modal-title").text("Buchungsanfrage am " + dateLabel);
  });

  $("#confirmBookingRequestModal").on("show.bs.modal", function (event) {
    var button = $(event.relatedTarget); // Button that triggered the modal
    var roomName = button.data("roomname"); // Extract info from data-* attributes
    var dateLabel = button.data("datelabel");
    var id = button.data("id");
    var name = button.data("name");
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this);
    modal
      .find("#sure")
      .text(
        "Sicher, dass Sie die Buchungsanfrage von " +
          name +
          " für " +
          dateLabel +
          " für den Raum " +
          roomName +
          " bestätigen wollen?"
      );
    modal.find("#booking-id").val(id);
    modal.find(".modal-title").text("Buchungsanfrage für " + dateLabel);
  });

  $("#rejectBookingRequestModal").on("show.bs.modal", function (event) {
    var button = $(event.relatedTarget); // Button that triggered the modal
    var roomName = button.data("roomname"); // Extract info from data-* attributes
    var dateLabel = button.data("datelabel");
    var id = button.data("id");
    var name = button.data("name");
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this);
    modal
      .find("#sure")
      .text(
        "Sicher, dass Sie die Buchungsanfrage von " +
          name +
          " für " +
          dateLabel +
          " für den Raum " +
          roomName +
          " ablehnen wollen?"
      );
    modal.find("#booking-id").val(id);
    modal.find("#booking-comment").val("");
    modal.find(".modal-title").text("Buchungsanfrage für " + dateLabel);
  });

  $("#cancelModal").on("show.bs.modal", function (event) {
    var button = $(event.relatedTarget); // Button that triggered the modal
    var roomName = button.data("roomname"); // Extract info from data-* attributes
    var dateLabel = button.data("datelabel");
    var date = button.data("date");
    var bookingId = button.data("booking-id");
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this);
    modal
      .find("#sure")
      .text(
        "Sind Sie sich sicher, dass Sie den Raum " +
          roomName +
          " am " +
          dateLabel +
          " wieder freigeben wollen?"
      );
    modal.find("#booking_id").val(bookingId);
    modal.find(".modal-title").text("Stornierung am " + dateLabel);
  });
});
